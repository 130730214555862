import logoHike from    './img/logos/light/uni-trek.png'
import logoActive from  './img/logos/light/uni-active.png'
import logoBall from    './img/logos/light/uni-ball.png'
import logoTrain from   './img/logos/light/uni-train.png'
import logoRun from     './img/logos/light/uni-run.png'

const Logos = {
  'hike': logoHike,
  'active': logoActive,
  'ball': logoBall,
  'train': logoTrain,
  'run': logoRun
}

export default Logos
