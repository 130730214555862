import catHike from './img/catHike.jpg'
import catActive from './img/catActive.jpg'
import catBall from './img/catBall.jpg'
import catTrain from './img/catTrain.jpg'
import catRun from './img/catRun.jpg'


const headerImages = {
  'hike': catHike,
  'active': catActive,
  'ball': catBall,
  'train': catTrain,
  'run': catRun
}

export default headerImages
