import {useState} from 'react'
import Box from './Box'
import Header from './Header'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import headerImages from './HeaderImages'
import Navigation from './Navigation'
import Logos from './Logos'

import act1 from './img/active-1.jpg'
import act2 from './img/active-2.jpg'
import act3 from './img/active-3.jpg'
import act4 from './img/active-4.jpg'

import hike1 from './img/hike-1.jpg'
import hike2 from './img/hike-2.jpg'
import hike3 from './img/hike-3.jpg'
import hike4 from './img/hike-4.jpg'

import ball1 from './img/ball-1.jpg'
import ball2 from './img/ball-2.jpg'
import ball3 from './img/ball-3.jpg'
import ball4 from './img/ball-4.jpg'

import train1 from './img/train-1.jpg'
import train2 from './img/train-2.jpg'
import train3 from './img/train-3.jpg'
import train4 from './img/train-4.jpg'

import run1 from './img/run-1.jpg'
import run2 from './img/run-2.jpg'
import run3 from './img/run-3.jpg'
import run4 from './img/run-4.jpg'

const hikeColors = [
  '#3d5159',
  '#365f82',
  '#62725e',
  '#c37a1f'
]

const ballColors = [
  '#023326',
  '#73a2d6',
  '#dc1f26',
  '#281a00'
]

const actColors = [
  '#365f82',
  '#62725e',
  '#5b5b5b',
  '#633b31'
]

const trainColors = [
  '#696a6d',
  '#262628',
  '#5b5b5b',
  '#d5d7d8'
]

const runColors = [
  '#de1f26',
  '#005d72',
  '#006bb7',
  '#c6e679'
]

const hikeImg  = [ hike1, hike2, hike3, hike4 ]
const ballImg  = [ ball1, ball2, ball3, ball4 ]
const runImg   = [ run1, run2, run3, run4 ]
const trainImg = [ train1, train2, train3, train4 ]
const actImg   = [ act1, act2, act3, act4 ]

function handleBoxEnter() {

}


function App() {

  const [hideNav, setHideNav] = useState(false)

  return (
    <Router>
      <div className="App" >
        <Navigation hide={hideNav} />
        <Switch>
          <Route path="/">
            <div className='scroll-container'>
              <Header enter={() => setHideNav(true)} exit={() => setHideNav(false)} />
              <div id={"trek"}>
                <div>
                  <Box hash="#trek" bg={headerImages.hike} next={'/#train'} logo={Logos.hike} gallery={hikeImg} colors={hikeColors} title='Explore the Outdoors' cta='UniPro Train' />
                </div>
              </div>
              <div id={'train'}> 
                <div>
                <Box hash="#train" bg={headerImages.train} next={'/#run'} logo={Logos.train} gallery={trainImg} colors={trainColors} title='Workout Life Balance' cta='UniPro Run' />
                </div>
              </div>
              <div id={'run'}>
                <div>
                <Box hash="#run" bg={headerImages.run} next={'/#bball'} logo={Logos.run} gallery={runImg} colors={runColors} title='Meet your Stride' cta='UniPro B•Ball' />
                </div>
              </div>
              <div id={'bball'}>
                <div>
                <Box hash="#bball" bg={headerImages.ball} next={'/#yoga'} logo={Logos.ball} gallery={ballImg} colors={ballColors} title='Court your Dreams' cta='UniPro Yoga' />
                </div>
              </div>
              <div id={'yoga'}>
                <div>
                <Box handle={handleBoxEnter} hash="#yoga" bg={headerImages.active} next={'/#home'} logo={Logos.active} gallery={actImg} colors={actColors} title='Stretch Your Goals' cta='Home' flip />
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
