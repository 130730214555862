
import React, { useState } from 'react'
import { Waypoint } from 'react-waypoint'
import classNames from 'classnames'
import tw, { styled } from 'twin.macro'
import logo from './img/logo.png'
import { HashLink } from 'react-router-hash-link';
import { useHistory } from 'react-router-dom';
import arrowSVG from './img/arrow.svg'

import headerImages from './HeaderImages'

const BoxContent = styled.div`
  ${tw`flex flex-col justify-center items-center delay-1000 duration-1000 relative bg-gray-700`}

  height: 101vh;

  .bg-contain {
    ${tw`absolute bg-no-repeat bg-cover opacity-0 top-0 left-0 w-full h-full pointer-events-none z-index[-1]`}
  }

  &.cat-hike .bg-contain {
    background-image: url('${headerImages.hike}');
    ${tw`duration-500 opacity-100`}
  }

  &.cat-active .bg-contain {
    background-image: url('${headerImages.active}');
    ${tw`duration-500 opacity-100`}
  }

  &.cat-ball .bg-contain {
    background-image: url('${headerImages.ball}');
    ${tw`duration-500 opacity-100`}
  }

  &.cat-train .bg-contain {
    background-image: url('${headerImages.train}');
    ${tw`duration-500 opacity-100`}
  }

  &.cat-run .bg-contain {
    background-image: url('${headerImages.run}');
    ${tw`duration-500 opacity-100`}
  }

  &.active {
    ${tw`text-white bg-gray-800 duration-500`}

    &.cat-hike {
      ${tw`duration-500 bg-transparent`}
    }

    &.cat-active {
      ${tw`duration-500 bg-transparent`}
    }
    &.cat-ball {
      ${tw`duration-500 bg-transparent`}
    }
    &.cat-train {
      ${tw`duration-500 bg-transparent`}
    }
    &.cat-run {
      ${tw`duration-500 bg-transparent`}
    }
  }

  .extra {
    ${tw`transition duration-2000 delay-1000 opacity-0 text-white px-3`}

    a {
      ${tw`p-1 px-2 m-2 border-b border-gray-400`}

      &:hover {
        ${tw`border-white`}
      }
    }

  }

  &.active .extra {
    opacity: 1;
  }

  .next-button {
    ${tw`transition duration-2000 delay-2000 opacity-0 text-white`}
  }

  &.active .next-button {
    opacity: 1;
  }

`

const NextArrow = styled.img`
  ${tw`transform -rotate-90 w-12 opacity-60 hover:opacity-100 transition mx-auto`}
`

const BoxContentInterior = styled.div`
  ${tw`flex flex-col justify-between h-full pb-8 pt-8`}
`

function Box({enter, exit}) {

  const [active, setActive] = useState(false)
  const [hoverCat, setHoverCat] = useState(false)
  const history = useHistory()

  const btnClass = classNames({
    'scroll-content': true,
    'active': active,
    'inactive': !active,
    'cat-hike': hoverCat === 'hike',
    'cat-active': hoverCat === 'active',
    'cat-ball': hoverCat === 'ball',
    'cat-train': hoverCat === 'train',
    'cat-run': hoverCat === 'run',
  })

  function handleEnter () {
    setActive(true)
    history.push('/#home')  
    enter()
  }

  function handleExit () {
    setActive(false)
    exit()
  }


  return (
    <Waypoint
      onEnter={() => handleEnter()}
      onLeave={() => handleExit()}
      topOffset='20px'
    >
      <BoxContent className={btnClass} id={"home"}>
        <div className='bg-contain'/>
        <BoxContentInterior>
          <div />
          <div tw='flex flex-col items-center justify-center'>
            <div tw='w-64 flex items-center justify-center'>
              <img src={logo} alt='logo' />
              {/* <Logo pixelWidth='350' color={active ? '#d7d7d7' : '#a3a3a3'}/> */}
            </div>
            <div tw='text-center mt-6 flex flex-row flex-wrap justify-center' className='extra' onMouseOut={()=>setHoverCat(false)}>
              <a href='#trek' onMouseOver={()=>setHoverCat('hike')}className='hover-cat'>Trek</a>
              <a href='#train' onMouseOver={()=>setHoverCat('train')}className='hover-cat'>Train</a>
              <a href='#run' onMouseOver={()=>setHoverCat('run')}className='hover-cat'>Run</a>
              <a href='#bball'onMouseOver={()=>setHoverCat('ball')}className='hover-cat'>B•Ball</a>
              <a href='#yoga'onMouseOver={()=>setHoverCat('active')}className='hover-cat'>Yoga</a>
            </div>
          </div>
          <HashLink to='/#trek' className='next-button'><NextArrow src={arrowSVG} alt='Next Section' /></HashLink>
        </BoxContentInterior>
        <img tw='hidden' src={headerImages.hike} alt='hike preload'/>
        <img tw='hidden' src={headerImages.train} alt='train preload'/>
        <img tw='hidden' src={headerImages.run} alt='run preload'/>
        <img tw='hidden' src={headerImages.ball} alt='ball preload'/>
        <img tw='hidden' src={headerImages.active} alt='active preload'/>
      </BoxContent>
    </Waypoint>
  );
}

export default Box;
