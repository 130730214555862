import {useState} from 'react'
import tw, { styled } from 'twin.macro'
import navLogo from './img/navLogo.png'
import { NavHashLink } from 'react-router-hash-link';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const Nav = styled.div`
    ${tw`fixed top-0 w-full md:h-16 z-10 bg-gray-200 flex flex-col md:flex-row py-3 md:py-0 justify-between items-center px-2 lg:px-20 shadow-2xl bg-opacity-90 transition duration-1000 text-xs sm:text-sm`}

    &.nav-hide { opacity: 0; }
    &.nav-show { opacity: 0.9; }

    a {
      ${tw`font-bold`}
    }
`

const Navigation = () => { 

    const [hideNav, setHideNav] = useState(true)

    useScrollPosition(({ prevPos, currPos }) => {
      const isHide = currPos.y === 0
      if (isHide !== hideNav) setHideNav(isHide)
    }, [hideNav])

    return (<Nav className={hideNav ? 'nav-hide': 'nav-show'}>
      <NavHashLink
        to="/#home"
        activeClassName="selected"
      >
      <img src={navLogo} tw='py-1 w-12' alt='UniPro'/>
      </NavHashLink>

    <ul tw='flex space-x-1 sm:space-x-2 lg:space-x-5'>

    <li>
      <NavHashLink
        to="/#trek"
        activeClassName="selected"
      >Trek</NavHashLink>
    </li>
    <li>
      <NavHashLink
        to="/#train"
        activeClassName="selected"
      >Train</NavHashLink>
    </li>
    <li>
      <NavHashLink
        to="/#run"
        activeClassName="selected"
      >Run</NavHashLink>
    </li>
    <li>
      <NavHashLink
        to="/#bball"
        activeClassName="selected"
      >B•Ball</NavHashLink>
    </li>
    <li>
      <NavHashLink
        to="/#yoga"
        activeClassName="selected"
      >Yoga</NavHashLink>
    </li>
  </ul>
</Nav>)}

export default Navigation
