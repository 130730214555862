import React from 'react'
import { createGlobalStyle } from 'styled-components'
import tw, { theme, GlobalStyles as BaseStyles } from 'twin.macro'

const CustomStyles = createGlobalStyle`

  html {
    scroll-behavior: smooth;
    background-color: rgb(31, 41, 55);
  }

  body {
    -webkit-tap-highlight-color: ${theme`colors.purple.300`};
    ${tw`antialiased`}
  }

  .scroll-container {
    /* scroll-snap-type: y proximity; */
    /* overflow-y: scroll;
    height: 100vh; */
  }

  .scroll-content {
    /* scroll-snap-align: start; */
  }
`

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
)

export default GlobalStyles
