
import { useState } from 'react'
import { Waypoint } from 'react-waypoint'
import classNames from 'classnames'
import tw, { theme, styled } from 'twin.macro'
import { HashLink } from 'react-router-hash-link'

import arrowSVG from './img/arrow.svg'

// import { useHistory } from 'react-router-dom';

const StyledHeadline = styled.h1`
  ${tw`mb-1 mt-8 text-xl`}
`

const BoxContent = styled.div`

  ${tw`flex flex-col text-black justify-center items-center transition delay-1000 duration-1000 bg-gray-700 bg-cover bg-center relative`}

  overflow: auto;

  @media (min-width: ${theme`screens.lg`}) {
    min-height: 100vh;
  }

  &.active {
    ${tw`bg-gray-800 text-black`}
  }

  img.brandCard {
    ${tw`transition duration-2000 transform md:translate-x-8 opacity-0`}
  }

  &.active img.brandCard {
    ${tw`transition delay-500 duration-2000 md:translate-x-0 opacity-100 border-b-8 shadow-md`}
  }

  .details {
    ${tw`opacity-0 md:-translate-x-8 transform transition justify-center pt-12 text-center md:text-left flex flex-col md:w-60 md:pr-8`}

    div.interior {
      ${tw`bg-black text-gray-200 p-4 shadow-2xl`}
    }

  }

  &.active .details {
    ${tw`transition delay-500 duration-2000 md:translate-x-0 opacity-100`}
  }

  .deadStrip {
    ${tw`transition delay-500 duration-2000 opacity-0 uppercase`}
  }

  &.active .deadStrip {
    ${tw` opacity-100`}
  }
`

const BoxContentInterior = styled.div`

  ${tw`flex flex-row px-4 md:px-8 md:m-8 mb-12 pb-2 bg-transparent delay-200 duration-2000 transition`}

  &.way-active {
    opacity: 1;
  }
`

const BrandCardStyle = styled.div`
  ${tw`flex flex-col lg:flex-row justify-center`}
`

const NextLink = styled(HashLink)`
  ${tw`absolute bottom-0 flex items-center px-8 pb-4`}
  .nextText {
    ${tw`text-white hidden`}
  }
  .nextArrow {
    ${tw`transform -rotate-90 w-12 opacity-60 hover:opacity-100 transition`}
  }
  .nextArrowFlip {
    ${tw`rotate-90`}
  }
`

const BrandCard = ({title, logo, gallery, colors}) => <BrandCardStyle>

  <div className='details'>
    <div className='interior'>
      <img tw='md:mb-8 w-40 md:w-full h-auto mx-auto md:mx-0' src={logo} alt='logo' />
      <div>
        <StyledHeadline>{title}</StyledHeadline>
      </div>
    </div>
  </div>

  <div tw='grid grid-cols-2 lg:max-w-xl gap-2 lg:gap-3 mt-8 mb-8'>
    <img className='brandCard' src={gallery[0]} alt={title} style={{borderColor:colors[0]}}/>
    <img className='brandCard' src={gallery[1]} alt={title} style={{borderColor:colors[1]}} />
    <img className='brandCard' src={gallery[2]} alt={title} style={{borderColor:colors[2]}} />
    <img className='brandCard' src={gallery[3]} alt={title} style={{borderColor:colors[3]}} />
  </div>

</BrandCardStyle>

function Box({title, colors, cta, bg, next, gallery, logo, flip}) {

  const [active, setActive] = useState(false)

  const btnClass = classNames({
    'scroll-content': true,
    'active': active,
    'inactive': !active
  });

  const activeClass = classNames({
    'way-active': active,
    'way-inactive': !active
  })

  const nextArrowClass =  classNames({
    'nextArrow': true,
    'nextArrowFlip': flip
  })

  return (
    <Waypoint
      onEnter={() => setActive(true)}
      onLeave={() => setActive(false)}
    >
      <BoxContent className={btnClass} style={{backgroundImage:`url('${bg}')`}}>
        <BoxContentInterior className={activeClass}>
          <BrandCard title={title} colors={colors} logo={logo} cta={cta} gallery={gallery} />
        </BoxContentInterior>
        <NextLink className='deadStrip' to={next}><div className='nextText'>{cta}</div><img className={nextArrowClass} src={arrowSVG} alt='Next Section' /></NextLink>
      </BoxContent>
    </Waypoint>
  );
}

export default Box;
